import { Container, Typography } from '@mui/material';

export default function Privacypolicy() {
  return (
    <Container>
      <Typography variant="h3" sx={{ mt: 4 }}>
        Terms and Conditions
      </Typography>

      <Typography paragraph>
        Please read the privacy policy carefully and understand all the terms before signing your
        acceptance and before using the Hakeemna.com website or any related applications or pages.
      </Typography>

      <Typography variant="h4">Definitions:</Typography>

      <Typography paragraph>
        The First Party refers to Hakeemna.com. When the term Platform Hakeemna.com or System is
        mentioned in this section, it includes the electronic platform, Android or iOS applications,
        associated websites, managers, and employees of the platform.
      </Typography>

      <Typography paragraph>
        The Second Party refers to users and beneficiaries of medical services provided by
        healthcare practitioners and suppliers in the healthcare sector. This includes patients and
        their families using the platform or visitors browsing the platform.
      </Typography>

      <Typography paragraph>
        The Third Party refers to medical service providers, such as clinics, health centers,
        radiology centers, psychologists, or medical supply institutions. These are individuals or
        entities licensed to work directly or indirectly in the healthcare sector in their country
        of residence.
      </Typography>

      <Typography variant="h4">Privacy Policy:</Typography>

      <Typography paragraph>
        Hakeemna.com values the importance of maintaining the privacy and confidentiality of users
        medical service information. When users input their information into the platform, there are
        mechanisms and laws in place to preserve that privacy.
      </Typography>

      <Typography paragraph>
        This section outlines the policy and terms of use for Hakeemna.com and related services and
        applications, including training provided by the platform. It also applies to any
        information collected about you, including when attending platform-related events. We may
        also provide notifications regarding different or additional privacy policies for specific
        activities, programs, and promotions, including additional timely notices that may
        complement or clarify our privacy practices or provide additional choices regarding your
        personal information.
      </Typography>

      <Typography variant="h4">Privacy Policies:</Typography>

      <Typography variant="h4">1. Information Stored on the Platform:</Typography>

      <Typography paragraph>a) Information entered by the user (Second Party):</Typography>

      <Typography paragraph>
        General information about the user (e.g., patient) required during initial registration,
        such as full name, national ID number, date of birth, phone number, email address,
        nationality, and place of residence.
      </Typography>

      <Typography paragraph>
        Additional general information (not mandatory) such as height, weight, exercise habits,
        chronic diseases, and a personal photo.
      </Typography>

      <Typography paragraph>
        Insurance company details, family member information if you are their guardian and have
        linked their personal accounts with yours, and optional medical information such as chronic
        diseases, medical history, medications taken, and various medical reports and documents.
      </Typography>

      <Typography paragraph>
        Users are legally responsible for the accuracy of the data they enter into the system and
        bear the legal consequences of entering incorrect or fraudulent data to affect the platform
        or other users, whether the user is a service beneficiary or a provider of medical services.
      </Typography>

      <Typography paragraph>
        b) Information entered by third parties (e.g., doctors) related to the user of medical
        services and their family members, such as medical information, prescriptions, sick leave.
      </Typography>

      <Typography paragraph>
        c) Information collected through surveys if necessary to obtain user information, noting
        that participation in surveys is optional.
      </Typography>

      <Typography paragraph>
        d) Information about the internet network you use, such as IP Address.
      </Typography>

      <Typography variant="h4">2. How User Information is Used:</Typography>

      <Typography paragraph>
        The data you enter and store is used to provide you with various services offered by
        Hakeemna.com, such as appointment scheduling, medical reports, medical history, and
        communication with doctors and suppliers.
      </Typography>

      <Typography paragraph>
        By entering and storing data on Hakeemna.com, you consent to the use of that data for the
        purposes and services provided by the platform.
      </Typography>

      <Typography paragraph>
        When you enter contact information such as phone number and email address, you agree to
        receive notifications and emails from Hakeemna.com. You can opt out of these notifications
        and emails partially based on the content of the notice or message.
      </Typography>

      <Typography paragraph>
        Hakeemna.com reserves the right to delete any data entered by users that may be considered a
        crime under electronic crime laws or may violate intellectual property rights of individuals
        or entities, or the rights of our platform. Therefore, the platform has the right to monitor
        and review the content posted by all parties using the platform (Second Party and Third
        Party). Note that this monitoring is not a mandatory regular practice.
      </Typography>

      <Typography paragraph>
        If a user closes their account on the platform, Hakeemna.com has the right to retain the
        data for record-keeping, historical information, and archiving purposes. Additionally, while
        maintaining user confidentiality and privacy, the platform may utilize the data for research
        and scientific purposes or other objectives.
      </Typography>

      <Typography variant="h4">3. Data Ownership and Protection:</Typography>

      <Typography paragraph>
        The information you store and your medical file available on your personal page on the
        platform are your private property. You can share this information with any medical service
        provider in your country or any other country.
      </Typography>

      <Typography paragraph>
        By agreeing to the privacy policy of Hakeemna.com, you consent to:
      </Typography>

      <Typography paragraph>
        If you make an appointment with a medical service provider, you agree that we may share your
        information with the medical service provider with whom you have an appointment on our
        platform.
      </Typography>

      <Typography paragraph>
        Part of your medical data stored on Hakeemna.com may be archived in electronic records held
        by the Third Party (medical service providers such as clinics or health centers) and may not
        be accessible to you. If you wish to obtain a copy, you must request it from them, not from
        Hakeemna.com. Note that the storage, organization, and protection of such data are the
        responsibility of the Third Party (practitioners and medical service providers) and not of
        Hakeemna.com.
      </Typography>

      <Typography paragraph>
        Regarding data and information stored with Third Parties (e.g., clinics) on Hakeemna.com,
        they may have different privacy policies from Hakeemna.com. Thus, sharing information with
        the user (Second Party) is subject to the laws of the country where the medical service
        provider operates, considering their specific privacy regulations.
      </Typography>

      <Typography paragraph>
        Personal data covered by this privacy policy that you voluntarily disclose by posting it on
        the platform becomes available to everyone. Please note that if you delete such information,
        a copy may still exist in our archived data as a backup. Similarly, when you share privacy
        data with other parties, it loses its privacy status, and the responsible party bears the
        risks and consequences of such sharing.
      </Typography>

      <Typography paragraph>
        If the law or any legal procedure requires the disclosure of personal information or other
        information about the user, Hakeemna.com is obligated to comply with the law and provide all
        required information to achieve justice.
      </Typography>

      <Typography variant="h4">4. Data Protection, Cybersecurity, and Other Risks:</Typography>

      <Typography paragraph>
        The security of your information is important to us. Therefore, please be aware that
        personal information is stored on servers outside the Hakeemna.com management location
        (Jordan) and may involve data storage services with companies having servers outside Jordan.
        By agreeing to the privacy policy, you acknowledge and consent to:
      </Typography>

      <Typography paragraph>
        Hakeemna.com collects, stores, and processes data on external servers. The platform
        implements security measures to protect information from unauthorized access, but due to
        various risks, we are committed to taking all possible precautions to prevent breaches but
        do not guarantee the prevention of breaches or data theft.
      </Typography>

      <Typography paragraph>
        If you provide us with personal information, it may be processed in a foreign country where
        privacy laws may be less stringent. By providing your personal information, you consent to
        the transfer, storage, and processing of your personal information in a country other than
        the Hakeemna.com management location and your country of residence.
      </Typography>

      <Typography paragraph>
        Users are responsible for protecting their password to access the system and for maintaining
        a backup of their data stored on the platform. Note that maintaining a backup of your data
        is not the responsibility of Hakeemna.com.
      </Typography>

      <Typography paragraph>
        Hakeemna.com will not be liable for any damages arising from the use of the website or
        related services or data loss. The platform is not legally or financially responsible for
        damages resulting from:
      </Typography>

      <Typography paragraph>
        - Misuse by the Second Party (platform users) and the resulting damages. - Misuse by the
        Third Party or any related application. - Promotional campaigns conducted by suppliers on
        the platform and their consequences. - Information published by users (Second Party) on the
        platform or other websites. - Medical errors or other direct or indirect damages from
        medical service providers or Third Parties.
      </Typography>

      <Typography paragraph>
        In the event of a security breach that is detected and controlled, affected individuals will
        be notified via the contact information stored in the system.
      </Typography>

      <Typography paragraph>
        Users must notify Hakeemna.com of any attempts or incidents of identity theft or
        unauthorized access to their account information on the platform.
      </Typography>

      <Typography paragraph>
        For security purposes, we may request additional information to verify your identity to
        process certain requests. In such cases, we may contact you via email or other available
        means to verify your identity and request.
      </Typography>

      <Typography paragraph>
        **Childrens Privacy:** The platform is not intended for children and teenagers under 18
        years old. We do not knowingly collect personal information from individuals under 18
        without parental consent. If we become aware that a child or teenager under 18 has provided
        personal information without parental consent, we will delete the information from our
        records.
      </Typography>

      <Typography variant="h4">5. Use and Utilization of Data:</Typography>

      <Typography paragraph>
        The data stored on the platform is used for the following purposes:
      </Typography>

      <Typography paragraph>
        - **Communication with You:** If you contact us via the website, we will use the personal
        information you provide to respond. We may also use personal information to communicate with
        you for other purposes, such as sharing updates about our products and services or offering
        relevant promotions from external partners.
      </Typography>

      <Typography paragraph>
        - **Analytics and Personalization:** We use personal information for research and analytics,
        including improving our website, understanding how you interact with our site, determining
        popular products or services, improving our marketing campaigns, and personalizing your
        experience.
      </Typography>

      <Typography paragraph>
        - **Legal and Judicial Obligations:** We may provide access to your information, including
        personal identification information, when legally required to do so, including complying
        with court orders, cooperating with police investigations, or related legal proceedings.
      </Typography>

      <Typography paragraph>
        We may also use information for other purposes disclosed to you at the time we collect the
        information. For example, we obtain specific consent from customers before publishing
        customer testimonials and reviews on our site, which may contain personal information.
      </Typography>

      <Typography paragraph>
        For scientific research and studies, if needed, data is aggregated and stored in a way that
        does not allow identifying or recognizing individual users (Second Party or Third Party) for
        research and scientific purposes.
      </Typography>

      <Typography paragraph>
        By agreeing to the privacy policy, you consent to your data being used as part of aggregated
        data for research and scientific purposes.
      </Typography>

      <Typography paragraph>
        Electronic archiving of the medical service users information.
      </Typography>

      <Typography paragraph>Providing specific and appropriate services to the user.</Typography>

      <Typography paragraph>Marketing services.</Typography>

      <Typography variant="h4">6. Changes to This Privacy Policy:</Typography>

      <Typography paragraph>
        Hakeemna.com may update the privacy policy for the website and applications at any time and
        for any reason. Therefore, we encourage you to review this page periodically for the latest
        information on our privacy practices.
      </Typography>

      <Typography paragraph>Last updated: 01/09/2024</Typography>

      <Typography variant="h4">Cookies Policy:</Typography>

      <Typography paragraph>
        Hakeemna.com does not use cookies on the page. The data in the possession of the platform is
        the data you store in the system after creating an account on the platform.
      </Typography>

      <Typography paragraph>Last updated: 01/09/2024</Typography>

      <Typography variant="h4">Liability and Indemnification:</Typography>

      <Typography paragraph>
        The platform and its affiliated applications do not provide any medical advice or
        consultations, nor do they sell any products. Any content or opinion is the responsibility
        of the person who posted it. The platform is not legally obligated to pursue any damages
        resulting from content or consultation. Legal pursuit and associated costs are beyond the
        platforms and its owners responsibility.
      </Typography>
    </Container>
  );
}
